<template>
  <div class="main-content bg-default">
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
              <img :src="siteLogo" class="square-img" />
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view :clpSitePageSettings="clpSitePageSettings"></router-view>
      </slide-y-up-transition>
    </div>
    <footer class="py-7">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-muted">
              &copy; {{ year }}
              <a
                href="https://www.iripple.com/"
                class="font-weight-bold ml-1"
                target="_blank"
                >iRipple, Inc.</a
              >
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';
import secureStorage from '../controllers/secureStorage';

export default {
  name: 'AuthLayout',
  components: {
    SlideYUpTransition
  },
  data() {
    return {
      year: new Date().getFullYear(),
      clpSitePageSettings: null
    };
  },

  created() {
    this.clpSitePageSettings = secureStorage.getItem('clpSitePageSettings');
  },

  computed: {
    siteLogo: function() {
      return this.clpSitePageSettings.site_logo !== undefined
        ? '/' + this.clpSitePageSettings.site_logo
        : '/';
    }
  }
};
</script>
<style scoped>
.square-img {
  max-height: 80px;
  max-width: 80px;
  height: auto;
  width: auto;
}
</style>
