<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link
        class="navbar-brand pb-0"
        :to="{
          name: 'Rewards',
          params: { siteName: clpSitePageSettings.siteName }
        }"
      >
        <img :src="siteLogo" class="navbar-brand-img" :alt="siteTitle" />
      </router-link>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <base-dropdown class="nav-item" position="right">
            <a slot="title" class="nav-link" href="#" role="button">
              <div class="media align-items-center">
                <span class="avatar avatar-sm rounded-circle">
                  <img
                    alt="Image placeholder"
                    src="img/theme/team-1-800x800.jpg"
                  />
                </span>
              </div>
            </a>

            <template>
              <div class=" dropdown-header noti-title">
                <h6 class="text-overflow m-0">Welcome!</h6>
              </div>
              <!-- <router-link
                :to="{
                  name: 'Rewards',
                  params: { siteName: clpSitePageSettings.siteName }
                }"
                class="dropdown-item"
              >
                <i class="ni ni-settings-gear-65"></i>
                <span>Settings</span>
              </router-link> -->
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item">
                <i class="ni ni-user-run"></i>
                <span>Logout</span>
              </a>
            </template>
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="siteLogo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click.native="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>
        <div class="text-center mb-3 mt-3">
          <!-- <span
            class="avatar rounded-circle mb-3"
            style="width: 80px; height: 80px;"
          >
            <img
              alt="Image placeholder"
              src="/img/brand/default-user-image.jpg"
            />
          </span> -->
          <h5 class="text-uppercase mb-0">Welcome, {{ memberName }}</h5>
          <span class="h6 font-weight-bold mb-0" v-if="showMemberId"
            >ID: {{ membershipNumber }}</span
          >
        </div>
        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from '@/components/NavbarToggleButton';

export default {
  name: 'sidebar',
  components: {
    NavbarToggleButton
  },
  props: {
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    },
    clpSitePageSettings: Object,
    memberData: Object,
    sitePageTheme: Object
  },
  provide() {
    return {
      autoClose: this.autoClose,
      membershipNumber: '-',
      memberData: null,
      clpSitePageSettings: null,
      siteTitle: null
    };
  },
  data() {
    return {
      membershipNumber: '-',
      showMemberId: true
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    }
  },
  created() {
    console.log('memberData: ', this.memberData);
    console.log('clpSitePageSettings: ', this.clpSitePageSettings);
    this.membershipNumber = this.memberData.membership_number;
    this.siteTitle = this.clpSitePageSettings.site_title;

    const fieldSettings = JSON.parse(this.clpSitePageSettings.field_settings);
    const membershipNumberField = fieldSettings.membership_number;

    this.showMemberId = membershipNumberField.is_visible;
  },
  computed: {
    memberName: function() {
      return this.memberData.first_name + ' ' + this.memberData.last_name;
    },

    siteLogo: function() {
      return '/' + this.clpSitePageSettings.site_logo;
    }
  },

  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
