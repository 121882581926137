import Vue from 'vue';
import Router from 'vue-router';

import DashboardLayout from '@/layout/DashboardLayout';
import AuthLayout from '@/layout/AuthLayout';

import HTTPRequest from '../controllers/httpRequest';
import secureStorage from '../controllers/secureStorage';

Vue.use(Router);

const beforeEnter = async (to, from, next) => {
  const siteName = to.params.siteName;
  const sitePage = await HTTPRequest.getRequest(
    '/api/v2/clp_site_pages/' + siteName + '/settings'
  );
  sitePage.siteName = siteName;

  console.log('to: ', to);
  console.log('from: ', from);
  console.log('sitePage: ', sitePage);

  if (sitePage.id) {
    /**
     * Check localStorage if `clpSitePageSettings` is null
     * OR the "current" site accessed is nozt equal to the locally stored `clpSitePageSettings` data
     */
    if (
      secureStorage.getItem('clpSitePageSettings') === null ||
      secureStorage.getItem('clpSitePageSettings').id !== sitePage.id
    ) {
      /** Save the new sitePageSettings to localStorage */
      secureStorage.setItem('clpSitePageSettings', sitePage);
      secureStorage.setItem('membershipProgramId', sitePage.membership_program_id);
      if (to.name !== 'MemberLogin') {
        console.log('Redirect to member login.');
        next({ name: 'MemberLogin', params: { siteName } });
      } else {
        console.log('Proceed to next route.');
        next();
      }
    } else if (
      /**
       * Check localStorage if `clpSitePageSettings` is not null
       * OR the "current" site accessed is equal to the locally stored `clpSitePageSettings` data
       */
      secureStorage.getItem('clpSitePageSettings') !== null ||
      secureStorage.getItem('clpSitePageSettings').id === sitePage.id
    ) {
      if (secureStorage.getItem('clpSitePageAccessToken') !== null) {
        /** Check if the "current" logged in user has an access to the site page */
        const me = await HTTPRequest.getRequest('/api/v2/clp_members/me');
        /** If has an error */
        if (me.error !== undefined) {
          killSession();
          if (me.error == 'Unauthorized') {
            if (to.name !== 'MemberLogin') {
              console.log('Redirect to member login.');
              next({
                name: 'MemberLogin',
                params: { siteName }
              });
            } else {
              console.log('Proceed to next route.');
              next();
            }
          }
        } else {
          console.log(2);
          /** Logged in user has an access to the site page */
          secureStorage.setItem('clpMemberData', me);
          next({ params: { siteName } });
        }
      } else {
        console.log(1);
        /** Save the new sitePageSettings to localStorage */
        secureStorage.setItem('clpSitePageSettings', sitePage);
        next({ params: { siteName } });
      }
    }
  } else if (sitePage.error !== null) {
    // Redirect to ANY Login page
    killSession();
    if (sitePage.error == 'Site Page not Found.') {
      console.log('Page not found. Redirecting ...');
      if (to.name !== 'LinkLogin') {
        next({ name: 'LinkLogin' });
      }
    } else {
      if (to.name !== 'MemberLogin') {
        next({ name: 'MemberLogin', params: { siteName } });
      }
    }
  } else {
    killSession();
    if (to.name !== 'LinkLogin') {
      next({ name: 'LinkLogin' });
    }
  }
};

const killSession = () => {
  console.log('Kill Session.');
  secureStorage.removeItem('clpSitePageSettings');
  secureStorage.removeItem('accessToken');
  secureStorage.removeItem('memberData');
};

export default new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      name: 'LinkLogin',
      path: '/login',
      component: () => import('@/views/Auth/LinkLogin.vue')
    },
    {
      path: '/:siteName',
      redirect: '/:siteName/login',
      component: AuthLayout,
      beforeEnter: beforeEnter,
      children: [
        {
          name: 'MemberLogin',
          path: 'login',
          component: () => import('@/views/Auth/MemberLogin.vue')
        },
        {
          name: 'AccountVerification',
          path: 'verify-account',
          component: () => import('@/views/Auth/AccountVerification.vue')
        },
        {
          name: 'SelfRegistration',
          path: 'sign-up',
          component: () => import('@/views/Auth/SelfRegistration.vue')
        },
        {
          name: 'YourProfile',
          path: 'your-profile',
          component: () => import('@/views/Auth/YourProfile.vue')
        },
        {
          name: 'ForgotPassword',
          path: 'forgot-password',
          component: () => import('@/views/Auth/ForgotPassword.vue')
        }
      ]
    },
    {
      path: '/:siteName',
      component: DashboardLayout,
      beforeEnter: beforeEnter,
      children: [
        {
          name: 'Rewards',
          path: 'rewards',
          component: () => import('@/views/Rewards.vue')
        },

        {
          name: 'Profile',
          path: 'profile',
          component: () => import('@/views/Member/MemberProfile.vue')
        },

        {
          name: 'PointsHistory',
          path: 'points-history',
          component: () => import('@/views/Transaction/PointsHistory.vue')
        }
      ]
    }
  ]
});
