<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    >
      <div class="form-group mb-0"></div>
    </form>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <div class="media align-items-center" slot="title">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="/img/brand/barter-logo.png" />
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              Welcome!
              <!-- <span class="mb-0 text-sm  font-weight-bold"
                ><b>{{ accountName }}</b> - {{ username }}</span
              > -->
            </div>
          </div>

          <template>
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <!-- <router-link
              :to="{
                name: 'Rewards',
                params: { siteName: clpSitePageSettings.siteName }
              }"
              class="dropdown-item"
            >
              <i class="ni ni-settings-gear-65"></i>
              <span>Settings</span>
            </router-link> -->
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item" @click="logout">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </template>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import secureStorage from '../controllers/secureStorage';

export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },

    logout() {
      console.log('Logout');
      const siteName = secureStorage.getItem('clpSitePageSettings').siteName;
      // secureStorage.removeItem('clpSitePageSettings');
      secureStorage.removeItem('clpSitePageAccessToken');
      secureStorage.removeItem('clpMemberData');

      this.$notification.success({
        message: 'Successfully logged out.'
      });

      setTimeout(() => {
        this.$router.push({ name: 'MemberLogin', params: { siteName } });
      }, 1000);
    }
  }
};
</script>
